<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('交易详情')"
        :class="crumbs == '交易详情' ? 'active' : 'left'"
        >交易详情</span
      >
      <span
        @click="navClick('退款详情')"
        :class="crumbs == '退款详情' ? 'active' : 'left'"
        >退款详情</span
      >
    </div>
    <div v-if="crumbs == '交易详情'">
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">交易信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary"
              >重发邮件</el-button
            >
          </div>
        </div>
        <ul class="listBox">
          <li>
            <span>商户订单号</span
            ><i class="hidden1">{{
              detail.mchOrderNo ? detail.mchOrderNo : ""
            }}</i>
          </li>
          <li>
            <span>交易流水号</span
            ><i class="hidden1">{{
              detail.plfOrderNo ? detail.plfOrderNo : ""
            }}</i>
          </li>
          <li>
            <span>三方订单号</span
            ><i class="hidden1">{{ detail.upsOrderNo }}</i>
          </li>
          <li>
            <span>三方流水号</span
            ><i class="hidden1">{{ detail.thdOrderNo == "null" || detail.thdOrderNo == null ?  "" : detail.thdOrderNo}}</i>
          </li>
          <li>
            <span>商户名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <b>{{ detail.mchName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户编号</span
            ><i class="hidden1">{{ detail.mchId ? detail.mchId : "" }}</i>
          </li>
          <li>
            <span>店铺名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <b>{{ detail.storeName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span
            ><i class="hidden1">{{ detail.storeId ? detail.storeId : "" }}</i>
          </li>
          <li>
            <span>报备商户</span
            ><i class="hidden1">{{
              detail.tradeMchName ? detail.tradeMchName : ""
            }}</i>
          </li>
          <li>
            <span>报备商户号</span
            ><i class="hidden1">{{
              detail.tradeMchNo ? detail.tradeMchNo : ""
            }}</i>
          </li>
          <li>
            <span>代理商名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.agentName"
                placement="top"
              >
                <b>{{ detail.agentName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>服务商名称</span
            ><i class="hidden1">{{
              detail.servicerName ? detail.servicerName : ""
            }}</i>
          </li>
          <li>
            <span>所属机构</span
            ><i class="hidden1">{{ detail.orgName ? detail.orgName : "" }}</i>
          </li>
          <li>
            <span>渠道商户号</span
            ><i class="hidden1">{{
              detail.channelMchNo ? detail.channelMchNo : ""
            }}</i>
          </li>
          <li>
            <span>创建时间</span
            ><i class="hidden1">{{
              detail.createTime ? detail.createTime : ""
            }}</i>
          </li>
          <li>
            <span>交易时间</span
            ><i class="hidden1">{{
              detail.tradeTime ? detail.tradeTime : ""
            }}</i>
          </li>
          <li>
            <span>产品类型</span
            ><i class="hidden1">{{
              detail.productName ? detail.productName : ""
            }}</i>
          </li>
          <li>
            <span>产品通道</span
            ><i class="hidden1">{{
              detail.payChannelName ? detail.payChannelName : ""
            }}</i>
          </li>
          <li>
            <span>交易状态</span
            ><i class="hidden1">{{ orderStatus[detail.orderStatus] }}</i>
          </li>
          <li>
            <span>交易金额</span
            ><i class="hidden1">{{
              detail.orderAmount ? detail.orderAmount / 100 : "" | formatMoney
            }}</i>
          </li>
          <li>
            <span>是否分账</span
            ><i class="hidden1">{{ detail.allocFund == 1 ? "是" : "否" }}</i>
          </li>
          <li>
            <span>分账类型</span
            ><i class="hidden1">{{ allocFundType[detail.allocFundType] }}</i>
          </li>
          <li>
            <span>附加数据</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.attach"
                placement="top"
              >
                <b>{{ detail.attach }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>付款银行</span
            ><i class="hidden1">{{
              bank[detail.bankCode] ? bank[detail.bankCode] : detail.bankCode
            }}</i>
          </li>
          <li>
            <span>商品名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.body"
                placement="top"
              >
                <b>{{ detail.body }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商品详情</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.body"
                placement="top"
              >
                <b>{{ detail.body }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>微信OpenID</span
            ><i class="hidden1">{{ detail.openId ? detail.openId : "" }}</i>
          </li>
          <li>
            <span>微信APPID</span
            ><i class="hidden1">{{ detail.appId ? detail.appId : "" }}</i>
          </li>
          <li>
            <span>服务器IP</span
            ><i class="hidden1">{{ detail.serverIp ? detail.serverIp : "" }}</i>
          </li>
          <li>
            <span>终端IP</span
            ><i class="hidden1">{{
              detail.terminalIp ? detail.terminalIp : ""
            }}</i>
          </li>
          <li>
            <span>通知地址</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.notifyUrl"
                placement="top"
              >
                <b>{{ detail.notifyUrl }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>页面回调地址</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.returnUrl"
                placement="top"
              >
                <b>{{ detail.returnUrl }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>响应码</span
            ><i class="hidden1">{{
              detail.resultCode ? detail.resultCode : ""
            }}</i>
          </li>
          <li>
            <span>响应码描述</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.resultMessage"
                placement="top"
              >
                <b>{{ detail.resultMessage }}</b>
              </el-tooltip>
            </i>
          </li>
          <!-- <li><span>业务结果</span><i>2013423343245</i></li> -->
          <!-- <li><span>错误描述</span><i>2013423343245</i></li> -->
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">交易通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr v-for="(v, i) in notices" :key="i">
            <td>
              <p>{{ v.notifyTime }}</p>
            </td>
            <td>
              <p>{{ notifyType[v.notifyType] }}</p>
            </td>
            <td>
              <p>{{ v.reachStatus ? "已到达" : "未响应" }}</p>
            </td>
            <td>
              <p>{{ notifyStatus[v.notifyStatus] }}</p>
            </td>
            <td>
              <p>{{ v.resultCode }}</p>
            </td>
            <td>
              <p>{{ v.resultMessage }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="crumbs == '退款详情'">
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">退款信息</h5>
        </div>
        <ul class="listBox">
          <li>
            <span>退款订单号</span
            ><i class="hidden1">{{
              detailTab.mchRefundNo ? detailTab.mchRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>退款流水号</span
            ><i class="hidden1">{{
              detailTab.plfRefundNo ? detailTab.plfRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>三方退款订单号</span
            ><i class="hidden1">{{
              detailTab.upsRefundNo ? detailTab.upsRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>三方退款流水号</span
            ><i>{{ detailTab.thdRefundNo ? detailTab.thdRefundNo : "" }}</i>
          </li>
          <li>
            <span>商户名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.mchName"
                placement="top"
              >
                <b>{{ detailTab.mchName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户编号</span
            ><i class="hidden1">{{ detailTab.mchId ? detailTab.mchId : "" }}</i>
          </li>
          <li>
            <span>店铺名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.storeName"
                placement="top"
              >
                <b>{{ detailTab.storeName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span
            ><i class="hidden1">{{
              detailTab.storeId ? detailTab.storeId : ""
            }}</i>
          </li>
          <li>
            <span>报备商户</span
            ><i class="hidden1">{{
              detailTab.tradeMchName ? detailTab.tradeMchName : ""
            }}</i>
          </li>
          <li>
            <span>报备商户号</span
            ><i class="hidden1">{{
              detailTab.tradeMchNo ? detailTab.tradeMchNo : ""
            }}</i>
          </li>
          <li>
            <span>代理商名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.agentName"
                placement="top"
              >
                <b>{{ detailTab.agentName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>服务商名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.servicerName"
                placement="top"
              >
                <b>{{ detailTab.servicerName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属机构</span
            ><i class="hidden1">{{
              detailTab.orgName ? detailTab.orgName : ""
            }}</i>
          </li>
          <li>
            <span>渠道商户号</span
            ><i class="hidden1">{{
              detailTab.channelMchNo ? detailTab.channelMchNo : ""
            }}</i>
          </li>
          <li>
            <span>创建时间</span
            ><i class="hidden1">{{
              detailTab.createTime ? detailTab.createTime : ""
            }}</i>
          </li>
          <li>
            <span>退款时间</span
            ><i class="hidden1">{{
              detailTab.refundTime ? detailTab.refundTime : ""
            }}</i>
          </li>
          <li>
            <span>产品类型</span
            ><i class="hidden1">{{
              detailTab.productName ? detailTab.productName : ""
            }}</i>
          </li>
          <li>
            <span>产品通道</span
            ><i class="hidden1">{{
              detailTab.payChannelName ? detailTab.payChannelName : ""
            }}</i>
          </li>
          <li>
            <span>交易金额</span
            ><i class="hidden1">{{
              detailTab.orderAmount
                ? detailTab.orderAmount / 100
                : "" | formatMoney
            }}</i>
          </li>
          <li>
            <span>交易退款金额</span
            ><i class="hidden1">{{
              detailTab.refundAmount
                ? detailTab.refundAmount / 100
                : "" | formatMoney
            }}</i>
          </li>
          <li>
            <span>营销退款金额</span
            ><i class="hidden1">{{
              detailTab.marketingRefundAmount
                ? detailTab.marketingRefundAmount / 100
                : "" | formatMoney
            }}</i>
          </li>
          <!-- <li><span>退款申请状态</span><i>{{detailTab.mchRefundNo}}</i></li> -->
          <li>
            <span>通知地址</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.notifyUrl"
                placement="top"
              >
                <b>{{ detailTab.notifyUrl }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>退款状态</span
            ><i class="hidden1">{{ refundStatus[detailTab.refundStatus] }}</i>
          </li>
          <li>
            <span>响应码</span
            ><i class="hidden1">{{
              detailTab.resultCode ? detailTab.resultCode : ""
            }}</i>
          </li>
          <li>
            <span>响应码描述</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.resultMessage"
                placement="top"
              >
                <b>{{ detailTab.resultMessage }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>异常码</span
            ><i class="hidden1">{{
              detailTab.errorCode ? detailTab.errorCode : ""
            }}</i>
          </li>
          <li>
            <span>异常描述</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detailTab.errorMessage"
                placement="top"
              >
                <b>{{ detailTab.errorMessage }}</b>
              </el-tooltip>
            </i>
          </li>
        </ul>
      </div>
      <div class="desc">
          <h5 class="jsxx">退款通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr v-for="(v, i) in noticesTab" :key="i">
            <td>
              <p>{{ v.notifyTime }}</p>
            </td>
            <td>
              <p>{{ notifyType[v.notifyType] }}</p>
            </td>
            <td>
              <p>{{ v.reachStatus ? "已到达" : "未响应" }}</p>
            </td>
            <td>
              <p>{{ notifyStatus[v.notifyStatus] }}</p>
            </td>
            <td>
              <p>{{ v.resultCode }}</p>
            </td>
            <td>
              <p>{{ v.resultMessage }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="commonBox"  v-if="wxNotices != undefined && wxNotices != ''">
      <div class="desc">
          <h5 class="jsxx">聚合码通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width:180px;">
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>通知内容</p>  
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in wxNotices" :key="i">
            <td>
              <p>{{ v.notifyTime }}</p>
            </td>
            <td>
              <p>微信</p>
            </td>
            <td>
              <p>{{ v.reachStatus != "NO" ? "已到达" : "未响应" }}</p>
            </td>
            <td>
              <p>{{ notifyStatus[v.notifyStatus] }}</p>
            </td>
            <td style="width:300px;">
              <p class="overTwo" style="width:300px;cursor: pointer;"  @click="wxNoticesContent(v.notifyContent)" >{{ v.notifyContent }}</p>
            </td>
            <td>
              <p>
                <i class="lianjie" ><a class="lianjie" href="JavaScript:;" @click="reissue(v.notifyId)" >补发</a></i>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog title="聚合码通知信息-通知内容" :visible.sync="wxNoticesShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul-left" style="width:100%;padding: 0px 24px;box-sizing: border-box;margin-left: 0px;">
          <div class="overAccount_ul_title">通知内容</div>
          <div class="hidden1" style="white-space: break-spaces;height: auto;">
            {{ wxNoticesContentTitle ? wxNoticesContentTitle : " " }}
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="addBt" @click="wxNoticesShow = false" style="margin-top:20px;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getReReDetail, getReDetail , getOrderReissue, getRefundIdReissue } from "@/api/trading/gathering.js";
import { getBank } from "@/utils/bank.js";
export default {
  data() {
    return {
      detail: "",
      detailTab: "",
      notices: [],
      noticesTab: [],
      crumbs: "退款详情",
      refundStatus: {
        WAIT: "待退款",
        REJECT: "已驳回",
        SUCCESS: "退款成功",
        FAIL: "退款失败",
        PROCESSING: "退款中",
        PASS: "审核通过",
      },
      orderStatus: {
        NOTPAY: "未支付",
        SUCCESS: "支付成功",
        REFUND: "转入退款",
        CLOSED: "订单关闭",
        PAYERROR: "支付失败",
        USERPAYING: "用户支付中",
        REVOKED: "已撤销",
      },
      notifyType: {
        PLATFORM: "平台",
        THIRD: "第三方",
      },
      notifyStatus: {
        SUCCESS: "成功",
        FAIL: "失败",
      },
      allocFundType: {
        11: "实时分账",
        12: "延迟分账",
        13: "多次分账",
      },
      bank: getBank(),
      wxNotices:"",
      wxNoticesShow:false,
      wxNoticesContentTitle:"",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.crumbs == "交易详情") {
        getReReDetail(this.$route.query.id).then((res) => {
          if (res) {
            this.detail = res.resultData.detail;
            this.notices = res.resultData.notices;
            this.wxNotices = res.resultData.wxNotices;
             console.log(this.wxNotices,"111111");
          }
        });
      }else if(this.crumbs == "退款详情"){
        getReDetail(this.$route.query.id).then((res) => {
          console.log(1111111111)
          if (res) {
            this.detailTab = res.resultData.detail;
            this.noticesTab = res.resultData.notices;
            this.wxNotices = res.resultData.wxNotices;
            console.log(this.wxNotices,"000000");
          }
        });
      }      
    },

    //查看聚合码通知内容
    wxNoticesContent(val){
      this.wxNoticesShow = true;
      this.wxNoticesContentTitle = val;
    },
    //补发
    reissue(notifyId){
      if (this.crumbs == "交易详情") {
        getOrderReissue(notifyId).then((res) => {
          if (res.resultStatus) {
            this.$message.success("补发请求成功");
            this.getDetail()
          }
        });
      } else if(this.crumbs == "退款详情"){
        getRefundIdReissue(notifyId).then((res) => {
          if (res.resultStatus) {
            this.$message.success("补发请求成功");
            this.getDetail()
          }
        });
      }      
    },

    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.wxNotices = "";
      this.getDetail();      
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
.overTwo{
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: break-spaces;
}

.ul_right {
  float: left;
  margin-left: 65px;
  width: calc(100% - 540px);
}

.ul_right li i {
  display: inline-block;
  vertical-align: middle;
  width: 290px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.ul_right li span {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.ul_right li {
  padding-bottom: 18px;
}

.ul_left li:last-child {
  padding-bottom: 0;
}

.ul_right li:last-child {
  padding-bottom: 0;
}

.ul_left li i {
  display: inline-block;
  vertical-align: middle;
  width: 290px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.ul_left li span {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.ul_left li {
  padding-bottom: 18px;
}

.ul_left {
  float: left;
  padding-left: 45px;
}

.content {
  width: 100%;
  overflow: hidden;
}

.content h4 {
  padding-left: 15px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 75px;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  padding-top: 20px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  width: 490px;
  float: left;
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul-left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
